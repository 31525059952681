<template>
  <div>
    <CartMain />
    <CartRecommend/>
  </div>
</template>

<script>
import CartMain from '@/components/cart/CartMain.vue';
import CartRecommend from '@/components/cart/CartRecommend.vue';

export default {
  name: 'CartPage',
  components: {
    CartRecommend,
    CartMain,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>
